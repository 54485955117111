<!-- 格口信息列表-详情 -->
<template>
    <a-modal :visible="true" centered :width="658" :title="'详情'" @cancel="handledClose" destroyOnClose :footer="null">
        <a-spin :spinning="state.currentLoading">
            <section class="content">
                <section>
                    <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript"
                        style="border-top: 1px solid #f0f0f0">
                        <a-descriptions-item label="格口编号" style="vertical-align: top;">{{ state.resData.boxNumber || '- -'}}</a-descriptions-item>
                        <a-descriptions-item label="物品类型">{{ state.resData.boxType == 1 ? '智能印章' : '- -'
                        }}</a-descriptions-item>
                        <a-descriptions-item label="在柜数量">{{ state.resData.nowNum || 0 }}</a-descriptions-item>
                        <a-descriptions-item label="格口名称">{{ state.resData.boxName || '- -' }}</a-descriptions-item>
                        <a-descriptions-item label="位置">第{{ state.resData.boxRow || 0 }}行，第{{ state.resData.boxCol
                        }}列</a-descriptions-item>
                        <a-descriptions-item label="格口状态">
                            <!-- （1.有物 2.空闲 3.异常） -->
                            <span class="dotted"
                                :style="{ background: state.resData.boxStatus == 1 ? '#A36FFF' : state.resData.boxStatus == 2 ? '#00CA5E' : '#FF1212' }"></span>
                            <span>{{ state.resData.boxStatus == 1 ? '有物' : state.resData.boxStatus == 2 ? '空闲' : '异常'
                            }}</span>
                        </a-descriptions-item>
                        <a-descriptions-item label="箱门状态">
                            <span :style="{ color: state.resData.boxOpenStatus == 1 ? '#FF9225' : '' }">{{
                                state.resData.boxOpenStatus == 1 ? '开启' : '关闭' }}</span>
                        </a-descriptions-item>
                    </a-descriptions>
                    <p v-if="state.bindNum > 0 && state.dataSource.length === 0" class="text-title">暂无数据</p>
                    <p v-if="state.dataSource.length > 0" class="text-title">设备列表</p>
                    <a-table v-if="state.dataSource.length > 0" class="table" :columns="modelColumns"
                        :dataSource="state.dataSource" rowKey="index" :pagination="false" ref="tableRef">
                        
                        <template #equipmentNumber="{ record }">
                             <a-tooltip placement="bottomLeft">
                                 <template #title>
                                     <span>{{ record.equipmentNumber || '- -' }}</span>
                                 </template>
                                 <span>{{ record.equipmentNumber || '- -' }} </span>
                             </a-tooltip>
                        </template>

                        <template #rfidNumber="{ record }">
                             <a-tooltip placement="bottomLeft">
                                 <template #title>
                                     <span>{{ record.rfidNumber || '- -' }}</span>
                                 </template>
                                 <span>{{ record.rfidNumber || '- -' }} </span>
                             </a-tooltip>
                        </template>

                        <template #sealName="{ record }">
                             <a-tooltip placement="bottomLeft">
                                 <template #title>
                                     <span>{{ record.sealName || '- -' }}</span>
                                 </template>
                                 <span>{{ record.sealName || '- -' }} </span>
                             </a-tooltip>
                        </template>
                        
                        <template #exceptionMsg="{ record }">
                             <a-tooltip placement="bottomLeft">
                                 <template #title>
                                     <span>{{ record.exceptionMsg === 1 ? '放错格口' : record.exceptionMsg === 2 ? '未绑定设备' : record.exceptionMsg === 3 ? '未知标签' : '- -' }}</span>
                                 </template>
                                 <span class="text-abnormal">{{record.exceptionMsg === 1 ? '放错格口' : record.exceptionMsg === 2 ? '未绑定设备' : record.exceptionMsg === 3 ? '未知标签' : '- -' }} </span>
                             </a-tooltip>
                        </template>
                    </a-table>
                </section>
            </section>
        </a-spin>
    </a-modal>
</template>
<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { modelColumns } from './columns'
import { sealCabinetApi } from '@/apis/sealCabinet'

export default defineComponent({

    props: {
        // 关闭弹窗方法
        handledClose: {
            required: true,
            type: Function
        },
        // 格口id
        cabinetBoxId: {
            required: true,
            type: Boolean,
        }
    },
    setup(props) {

        let state = reactive({
            // 接口data
            resData: {},
            // 页面数据加载loading
            currentLoading: false,
            // 表格数据
            dataSource: [],
            bindNum: 0
        });

        // 初始化
        onMounted(() => {
            getDetail()
        })

        // 获取详情数据
        const getDetail = () => {
            state.currentLoading = true;
            sealCabinetApi.goodsNowDetail({
                cabinetBoxId: props.cabinetBoxId
            }).then(res => {
                state.resData = res.data;
                state.dataSource = res.data.goodsDetailList;
                state.currentLoading = false;
                state.bindNum = res.data.bindNum;
            })
        }

        return {
            state,
            modelColumns
        }
    }

})
</script>
<style lang="scss" scoped>
:deep(.ant-modal-title) {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;
}

:deep(.ant-timeline-item-last) {
    padding-bottom: 0;
}

.content {
    max-height: 519px;
    overflow-y: auto;

    :deep(.ant-descriptions-item-label) {
        color: rgba(0, 0, 0, 0.45);
    }

    :deep(.ant-descriptions-item-content) {
        color: rgba(0, 0, 0, 0.65);
    }

    .mark {
        >p {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            margin-bottom: 8px;
        }

        :deep(.ant-input-textarea-show-count::after) {
            top: -25px;
        }
    }

    .descript {
        :deep(.ant-descriptions-item-label) {
            width: 50%;
        }
    }

    :deep(.ant-descriptions-view:nth-of-type(1)) {
        border-top: none;
    }
}

.dotted {
    width: 7px;
    height: 7px;
    margin-right: 5px;
    display: inline-block;
    border-radius: 50%;
    transform: translateY(-1px);
}

.text-title {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin: 24px 0 8px;
}

.text-abnormal {
    color: #C3161C;
}
</style>

